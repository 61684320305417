import {
  Button,
  Col,
  Divider,
  Grid,
  message,
  Modal,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import React, { useState, useEffect } from 'react';
import slackLogo from '../../../assets/icons/Slack.svg';
import hubspotLogo from '../../../assets/icons/hubspot.svg';
import calendarLogo from '../../../assets/icons/Calendar.svg';
import checkLogo from '../../../assets/icons/check.svg';
import salesforceLogo from '../../../assets/icons/Salesforce.svg';
import { doDelete } from '../../../sdk/Events';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import {
  connectCalendar,
  connectHubspot,
  connectMS,
  connectSalesForce,
  connectSlack,
} from '../../../utils/IntegrationHelper';
import SlackConnectModal from './SlackConnectModal';
import HubspotConnectModal from './HubspotConnectModal';
import DisconnectModal from './DisconnectModal';
import SalesforceConnectModal from './SalesforceConnectModal';
// import { WinrateImages } from '../../../assets/Images';
import { ssoType } from '../../../common/Utils';

interface IntegrationProps {
  loading: boolean;
  integrationStatus: any;
  user_id: string;
  getIntegrationStatus: () => void;
}

const Integrations: React.FC<IntegrationProps> = ({
  loading,
  integrationStatus,
  user_id,
  getIntegrationStatus,
}) => {
  const [disconnectLoader, setDisconnectLoader] = useState<{
    [key: string]: boolean;
  }>({});

  const [slackModalOpen, setSlackModalOpen] = useState(false);
  const [hubspotModalOpen, setHubspotModalOpen] = useState(false);
  const [salesforceModalOpen, setSaleforceModalOpen] = useState(false);
  const [disConnectModal, setDisConnectModal] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState('');
  const [disconnectGoogleModal, setDisConnectGoogleModal] = useState(false);
  const [disconnectMSModal, setDisConnectMSModal] = useState(false);
  const [connectLoading, setConnectLoading] = useState(false);

  useEffect(() => {
    const fromAuthCallback = new URLSearchParams(window.location.search);
    const auth = fromAuthCallback.get('auth');
    const error = fromAuthCallback.get('message');
    if (auth === 'failed') {
      message.error(error);
    }
  }, []);

  const disconnectIntegration = async (e: any) => {
    setDisconnectLoader((prev) => ({ ...prev, [e]: true }));
    try {
      const path = `setting/${e}/${user_id}`;
      await doDelete(path);
      setTimeout(async () => {
        await getIntegrationStatus();
        message.success('Disconnected successfully');
        setDisconnectLoader((prev) => ({ ...prev, [e]: false }));
        setDisConnectModal(false);
      }, 1000);
    } catch (error: any) {
      setDisconnectLoader((prev) => ({ ...prev, [e]: false }));
      message.error(error.message);
    }
  };

  //commented microsoft code for temporary
  // const connectMicrosoft = async () => {
  //   try {
  //     if (integrationStatus.google === 'connected') {
  //       setDisConnectGoogleModal(true);
  //     } else {
  //       connectMS(user_id, 'notification');
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const handleMSConnect = async () => {
    try {
      setConnectLoading(true);
      const path = `setting/google/${user_id}`;
      await doDelete(path)
        .then(() => {
          message.success('Disconnected successfully');
          connectMS(user_id, 'notification');
        })
        .catch((err) => console.error(err));
      setConnectLoading(false);
    } catch (err) {
      setConnectLoading(false);
      console.error(err);
    }
  };

  const connectGoogle = async () => {
    try {
      if (integrationStatus.microsoft === 'connected') {
        setDisConnectMSModal(true);
      } else {
        connectCalendar(user_id, 'notification');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleGoogleConnect = async () => {
    try {
      setConnectLoading(true);
      const path = `setting/microsoft/${user_id}`;
      await doDelete(path)
        .then(() => {
          message.success('Disconnected successfully');
          connectCalendar(user_id, 'notification');
        })
        .catch((err) => console.error(err));
      setConnectLoading(false);
    } catch (err) {
      setConnectLoading(false);
      console.error(err);
    }
  };

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  return (
    <div>
      <Row align="middle" gutter={[16, 16]} className="integration-row">
        {screens.md && (
          <Col
            md={1}
            xl={1}
            lg={1}
            style={{ textAlign: 'center' }}
            className="p-0"
          >
            <img src={slackLogo} />
          </Col>
        )}

        <Col md={19} sm={24} xs={24} xl={19} lg={19}>
          <Row align="middle">
            {!screens.md && <img src={slackLogo} />}{' '}
            <Typography.Text
              className={!screens.md ? 'fs-16 fw-600 ml-10' : 'fs-16 fw-500'}
            >
              Slack
            </Typography.Text>
          </Row>
          <Row align="middle">
            <Typography.Text className={!screens.md ? 'mt-10' : ''}>
              Get instant notifications about important updates and meeting
              reminders directly in Slack.{' '}
            </Typography.Text>
            {integrationStatus.slack !== 'connected' && (
              <Button
                type="text"
                className="p-0 branding-color fw-500 connect-text-height"
                onClick={() => setSlackModalOpen(true)}
              >
                Why Connect Slack?
              </Button>
            )}
          </Row>
        </Col>
        {loading ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <Skeleton.Button active className="skeleton-btn" />
          </Col>
        ) : integrationStatus.slack === 'connected' ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <SecondaryButton
              text="Disconnect"
              className={screens.xs ? 'connect-btn ' : ''}
              onClick={() => {
                setDisConnectModal(true);
                setSelectedIntegration('slack');
              }}
            />
          </Col>
        ) : (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <PrimaryButton
              onClick={() => connectSlack(user_id, 'notification')}
              text="Connect"
              className={
                screens.xs ? 'connect-btn integration-cta' : 'integration-cta'
              }
              disabled={loading}
            />
          </Col>
        )}
      </Row>

      <Divider className="divider-margin" />

      <Row align="middle" gutter={[16, 16]} className="integration-row">
        {screens.md && (
          <Col
            md={1}
            xl={1}
            lg={1}
            style={{ textAlign: 'center' }}
            className="p-0"
          >
            <img src={hubspotLogo} />
          </Col>
        )}

        <Col md={19} sm={24} xs={24} xl={19} lg={19}>
          <Row align="middle">
            {!screens.md && <img src={hubspotLogo} />}{' '}
            <Typography.Text
              className={!screens.md ? 'fs-16 fw-600 ml-10' : 'fs-16 fw-500'}
            >
              HubSpot
            </Typography.Text>
          </Row>
          <Row align="middle">
            <Typography.Text className={!screens.md ? 'mt-10' : ''}>
              Sync up-to-date lead activities and engagement history to enrich
              your prep notes.
            </Typography.Text>
            {integrationStatus.hubspot !== 'connected' && (
              <Button
                type="text"
                className="p-0 branding-color fw-500 connect-text-height"
                onClick={() => setHubspotModalOpen(true)}
              >
                Why Connect Hubspot?
              </Button>
            )}
          </Row>
        </Col>
        {loading ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <Skeleton.Button active className="skeleton-btn" />
          </Col>
        ) : integrationStatus.hubspot === 'connected' ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <SecondaryButton
              text="Disconnect"
              onClick={() => {
                setDisConnectModal(true);
                setSelectedIntegration('hubspot');
              }}
            />
          </Col>
        ) : (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <PrimaryButton
              text="Connect"
              className="integration-cta"
              onClick={() => connectHubspot(user_id, 'notification')}
              disabled={loading || integrationStatus.salesforce === 'connected'}
            />
          </Col>
        )}
      </Row>

      {(localStorage.getItem('sso_type') === ssoType.oidc &&
        integrationStatus.google === 'connected') ||
      (localStorage.getItem('sso_type') === ssoType.Google &&
        integrationStatus.google === 'connected') ||
      (localStorage.getItem('sso_type') === ssoType.Google &&
        integrationStatus.microsoft === 'connected') ||
      (localStorage.getItem('sso_type') === ssoType.Google &&
        integrationStatus.microsoft === 'disconnected' &&
        integrationStatus.google === 'disconnected') ||
      (!localStorage.getItem('sso_type') &&
        (integrationStatus.google === 'connected' ||
          integrationStatus.google === 'disconnected')) ? (
        <>
          <Divider className="divider-margin" />
          <Row align="middle" gutter={[16, 16]} className="integration-row">
            {screens.md && (
              <Col
                md={1}
                xl={1}
                lg={1}
                style={{ textAlign: 'center' }}
                className="p-0"
              >
                <img src={calendarLogo} />
              </Col>
            )}

            <Col md={19} sm={24} xs={24} xl={19} lg={19}>
              <Row align="middle">
                {!screens.md && <img src={calendarLogo} />}{' '}
                <Typography.Text
                  className={
                    !screens.md ? 'fs-16 fw-600 ml-10' : 'fs-16 fw-500'
                  }
                >
                  Google Calendar
                </Typography.Text>
              </Row>
            </Col>
            {loading ? (
              <Col
                md={4}
                sm={24}
                xs={24}
                xl={4}
                lg={4}
                style={{ textAlign: 'right' }}
              >
                <Skeleton.Button active className="skeleton-btn" />
              </Col>
            ) : localStorage.getItem('sso_type') === ssoType.oidc &&
              integrationStatus.google === 'connected' ? (
              <Col
                md={4}
                sm={24}
                xs={24}
                xl={4}
                lg={4}
                style={{ textAlign: 'right' }}
              >
                <Button type="text" className="p-0">
                  <img src={checkLogo} alt="Checked" />
                  <span>Connected</span>
                </Button>
              </Col>
            ) : localStorage.getItem('sso_type') === ssoType.Google &&
              integrationStatus.google === 'connected' ? (
              <Col
                md={4}
                sm={24}
                xs={24}
                xl={4}
                lg={4}
                style={{ textAlign: 'right' }}
              >
                <Button type="text" className="p-0">
                  <img src={checkLogo} alt="Checked" />
                  <span>Connected</span>
                </Button>
              </Col>
            ) : localStorage.getItem('sso_type') === ssoType.Google &&
              integrationStatus.google === 'disconnected' ? (
              <Col
                md={4}
                sm={24}
                xs={24}
                xl={4}
                lg={4}
                style={{ textAlign: 'right' }}
              >
                <PrimaryButton
                  onClick={() => connectGoogle()}
                  text="Connect"
                  className="integration-cta"
                  disabled={loading}
                />
              </Col>
            ) : !localStorage.getItem('sso_type') &&
              integrationStatus.google === 'disconnected' ? (
              <Col
                md={4}
                sm={24}
                xs={24}
                xl={4}
                lg={4}
                style={{ textAlign: 'right' }}
              >
                <PrimaryButton
                  onClick={() => connectGoogle()}
                  text="Connect"
                  className="integration-cta"
                  disabled={loading}
                />
              </Col>
            ) : (
              !localStorage.getItem('sso_type') &&
              integrationStatus.google === 'connected' && (
                <Col
                  md={4}
                  sm={24}
                  xs={24}
                  xl={4}
                  lg={4}
                  style={{ textAlign: 'right' }}
                >
                  <Button type="text" className="p-0">
                    <img src={checkLogo} alt="Checked" />
                    <span>Connected</span>
                  </Button>
                </Col>
              )
            )}
          </Row>
        </>
      ) : null}

      {/* commented microsoft code for temporary */}
      {/* {(localStorage.getItem('sso_type') === ssoType.Google &&
        integrationStatus.microsoft === 'connected') ||
      (localStorage.getItem('sso_type') === ssoType.oidc &&
        integrationStatus.microsoft === 'connected') ||
      (localStorage.getItem('sso_type') === ssoType.oidc &&
        integrationStatus.google === 'connected') ||
      (localStorage.getItem('sso_type') === ssoType.oidc &&
        integrationStatus.microsoft === 'disconnected' &&
        integrationStatus.google === 'disconnected') ||
      (!localStorage.getItem('sso_type') &&
        (integrationStatus.microsoft === 'connected' ||
          integrationStatus.microsoft === 'disconnected')) ? (
        <>
          <Divider className="divider-margin" />
          <Row align="middle" gutter={[16, 16]} className="integration-row">
            {screens.md && (
              <Col
                md={1}
                xl={1}
                lg={1}
                style={{ textAlign: 'center' }}
                className="p-0"
              >
                <img src={WinrateImages.Icons.calendarOutlookIcon} />
              </Col>
            )}

            <Col md={19} sm={24} xs={24} xl={19} lg={19}>
              <Row align="middle">
                {!screens.md && (
                  <img src={WinrateImages.Icons.calendarOutlookIcon} />
                )}{' '}
                <Typography.Text
                  className={
                    !screens.md ? 'fs-16 fw-600 ml-10' : 'fs-16 fw-500'
                  }
                >
                  Outlook Calendar
                </Typography.Text>
              </Row>
            </Col>
            {loading ? (
              <Col
                md={4}
                sm={24}
                xs={24}
                xl={4}
                lg={4}
                style={{ textAlign: 'right' }}
              >
                <Skeleton.Button active className="skeleton-btn" />
              </Col>
            ) : localStorage.getItem('sso_type') === ssoType.Google &&
              integrationStatus.microsoft === 'connected' ? (
              <Col
                md={4}
                sm={24}
                xs={24}
                xl={4}
                lg={4}
                style={{ textAlign: 'right' }}
              >
                <Button type="text" className="p-0">
                  <img src={checkLogo} alt="Checked" />
                  <span>Connected</span>
                </Button>
              </Col>
            ) : localStorage.getItem('sso_type') === ssoType.oidc &&
              integrationStatus.microsoft === 'connected' ? (
              <Col
                md={4}
                sm={24}
                xs={24}
                xl={4}
                lg={4}
                style={{ textAlign: 'right' }}
              >
                <Button type="text" className="p-0">
                  <img src={checkLogo} alt="Checked" />
                  <span>Connected</span>
                </Button>
              </Col>
            ) : localStorage.getItem('sso_type') === ssoType.oidc &&
              integrationStatus.microsoft === 'disconnected' ? (
              <Col
                md={4}
                sm={24}
                xs={24}
                xl={4}
                lg={4}
                style={{ textAlign: 'right' }}
              >
                <PrimaryButton
                  onClick={() => connectMicrosoft()}
                  text="Connect"
                  className="settings_action_btn"
                  disabled={loading}
                />
              </Col>
            ) : !localStorage.getItem('sso_type') &&
              integrationStatus.microsoft === 'disconnected' ? (
              <Col
                md={4}
                sm={24}
                xs={24}
                xl={4}
                lg={4}
                style={{ textAlign: 'right' }}
              >
                <PrimaryButton
                  onClick={() => connectMicrosoft()}
                  text="Connect"
                  className="settings_action_btn"
                  disabled={loading}
                />
              </Col>
            ) : (
              !localStorage.getItem('sso_type') &&
              integrationStatus.microsoft === 'connected' && (
                <Col
                  md={4}
                  sm={24}
                  xs={24}
                  xl={4}
                  lg={4}
                  style={{ textAlign: 'right' }}
                >
                  <Button type="text" className="p-0">
                    <img src={checkLogo} alt="Checked" />
                    <span>Connected</span>
                  </Button>
                </Col>
              )
            )}
          </Row>
        </>
      ) : null} */}

      <Divider className="divider-margin" />
      <Row align="middle" gutter={[16, 16]} className="integration-row">
        {screens.md && (
          <Col
            md={1}
            xl={1}
            lg={1}
            style={{ textAlign: 'center' }}
            className="p-0"
          >
            <img src={salesforceLogo} />
          </Col>
        )}

        <Col md={19} sm={24} xs={24} xl={19} lg={19}>
          <Row align="middle">
            {!screens.md && <img src={salesforceLogo} />}{' '}
            <Typography.Text
              className={!screens.md ? 'fs-16 fw-600 ml-10' : 'fs-16 fw-500'}
            >
              Salesforce
            </Typography.Text>
          </Row>
          <Row align="middle">
            <Typography.Text className={!screens.md ? 'mt-10' : ''}>
              Connecting Salesforce lets you bring in valuable CRM data for your
              meeting prep.
            </Typography.Text>
            {integrationStatus.salesforce !== 'connected' && (
              <Button
                type="text"
                className="p-0 branding-color fw-500 connect-text-height"
                onClick={() => setSaleforceModalOpen(true)}
              >
                Why Connect Salesforce?
              </Button>
            )}
          </Row>
        </Col>
        {loading ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <Skeleton.Button active className="skeleton-btn" />
          </Col>
        ) : integrationStatus.salesforce === 'connected' ? (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <SecondaryButton
              text="Disconnect"
              onClick={() => {
                setDisConnectModal(true);
                setSelectedIntegration('salesforce');
              }}
            />
          </Col>
        ) : (
          <Col
            md={4}
            sm={24}
            xs={24}
            xl={4}
            lg={4}
            style={{ textAlign: 'right' }}
          >
            <PrimaryButton
              text="Connect"
              className="integration-cta"
              onClick={() => connectSalesForce(user_id, 'notification')}
              disabled={loading || integrationStatus.hubspot === 'connected'}
            />
          </Col>
        )}
      </Row>
      <SlackConnectModal
        setSlackModalOpen={setSlackModalOpen}
        slackModalOpen={slackModalOpen}
        user_id={user_id}
        integrationStatus={integrationStatus}
        disconnectIntegration={disconnectIntegration}
      />
      <HubspotConnectModal
        setHubspotModalOpen={setHubspotModalOpen}
        hubspotModalOpen={hubspotModalOpen}
        user_id={user_id}
      />
      <SalesforceConnectModal
        setSaleforceModalOpen={setSaleforceModalOpen}
        salesforceModalOpen={salesforceModalOpen}
        user_id={user_id}
      />
      <DisconnectModal
        setDisConnectModal={setDisConnectModal}
        disConnectModal={disConnectModal}
        selectedIntegration={selectedIntegration}
        disconnectIntegration={disconnectIntegration}
        disconnectLoader={disconnectLoader}
        integrationStatus={integrationStatus}
      />
      <Modal
        open={disconnectGoogleModal}
        onCancel={() => setDisConnectGoogleModal(false)}
        footer={null}
      >
        <Row justify="center">
          <Typography.Title level={4}>
            Update Calendar Integration
          </Typography.Title>
        </Row>
        <Row justify="center" className="mt-20">
          <Typography.Text className="text-center">
            You&apos;re about to connect a new calendar. This will disconnect
            your existing Google Calendar integration. Are you sure you want to
            proceed?
          </Typography.Text>
        </Row>
        <Row justify="center" className="mt-20">
          <PrimaryButton
            text="Switch Calendar"
            onClick={handleMSConnect}
            loading={connectLoading}
            className="switch-btn"
          />
        </Row>
        <Row justify="center" className="mt-10">
          <Button
            type="text"
            onClick={() => setDisConnectGoogleModal(false)}
            className="later-btn"
          >
            I&apos;ll Do it Later
          </Button>
        </Row>
      </Modal>

      <Modal
        open={disconnectMSModal}
        onCancel={() => setDisConnectMSModal(false)}
        footer={null}
      >
        <Row justify="center">
          <Typography.Title level={4}>
            Update Calendar Integration
          </Typography.Title>
        </Row>
        <Row justify="center" className="mt-20">
          <Typography.Text className="text-center">
            You&apos;re about to connect a new calendar. This will disconnect
            your existing Outlook Calendar integration. Are you sure you want to
            proceed?
          </Typography.Text>
        </Row>

        <Row justify="center" className="mt-20">
          <PrimaryButton
            text="Switch Calendar"
            onClick={handleGoogleConnect}
            loading={connectLoading}
            className="switch-btn"
          />
        </Row>
        <Row justify="center" className="mt-10">
          <Button
            type="text"
            onClick={() => setDisConnectMSModal(false)}
            className="later-btn"
          >
            I&apos;ll Do it Later
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

export default Integrations;

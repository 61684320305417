import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Skeleton,
  Divider,
  Typography,
  Row,
  Col,
  Button,
  Breadcrumb,
  message,
  Grid,
} from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { NotesHeader } from './components/NotesHeader';
import { AboutSection } from './components/About/AboutCompany';
import { AttendeeSection } from './components/Attendees/List';
import { CatchUp } from './components/ContextSources/GetCaughtUp';
import { Research } from './components/CustomResearch/Research';
import { doPut, getMeetingNotes } from '../sdk/Events';
import { timeAgo } from './utils';
import './MeetingNotes.less';
import { doGet } from '../sdk/Events';
import { MeetingNote, Events, Block } from './MeetingNotesTypes';
import { ChatWrapper } from './components/Chat/ChatWrapper';
import { ABOUT_KEY, ATTENDEES_KEY, CALL_CONTEXT_KEY } from './PrepnoteHelper';
import SubscriptionExpiredModal from '../MyAccount/components/Subscription/SubscriptionExpiredModal';
import { setDateChange } from '../store/authSlice';
import { useDispatch } from 'react-redux';
import ShareNote from './components/ShareNote';

const MAX_ATTEMPTS = 100;

const DEFAULT_BLOCKS = [
  { title: 'About', is_hidden: false, identifier: ABOUT_KEY },
  {
    title: 'Who is coming to the call',
    is_hidden: false,
    identifier: ATTENDEES_KEY,
  },
  { title: 'Get caught up', is_hidden: false, identifier: CALL_CONTEXT_KEY },
];

let timeoutRef = null as any;

export const MeetingNotes = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const attendeesRef = useRef<HTMLDivElement>(null);
  const caughtUpRef = useRef<HTMLDivElement>(null);
  const researchRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const { id = '' } = useParams();
  const [meetingSummary, setMeetingSummary] = useState({} as any);
  const [meetingEventData, setMeetingEventData] = useState({} as any);
  const [meetingNotes, setMeetingNotes] = useState({} as MeetingNote);
  const [updatedTime, setUpdatedTime] = useState({} as Events);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingBlocks, setLoadingBlocks] = useState(true);
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [initialUpdatedAt, setInitialUpdatedAt] = useState<string | null>(null);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [noteStatus, setNoteStatus] = useState('');
  const storedUserDetails = localStorage.getItem('UserDetails');
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  const [showEndSubscriptionModal, setShowEndSubscriptionModal] =
    useState(false);
  const modalOpenedRef = useRef(false);
  const chatContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();
  const handleScrollToSection = (sectionIdentifier: string) => {
    setSelectedSection(sectionIdentifier);
    let targetRef = null;

    switch (sectionIdentifier) {
      case ABOUT_KEY:
        targetRef = aboutRef;
        break;
      case CALL_CONTEXT_KEY:
        targetRef = caughtUpRef;
        break;
      case ATTENDEES_KEY:
        targetRef = attendeesRef;
        break;
      default:
        // For custom research blocks
        targetRef = { current: researchRefs.current[sectionIdentifier] };
        break;
    }

    if (targetRef?.current) {
      targetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const updateMeetingNotes = (summary: any) => {
    const mtngnotes = summary;
    if (
      !mtngnotes?.company?.profile?.domain &&
      mtngnotes?.company?.profile?.website
    ) {
      const url = new URL(mtngnotes?.company?.profile?.website);
      mtngnotes.company.profile.domain = url.hostname.replace('www.', '');
    }
    setMeetingNotes(mtngnotes || {});
  };

  const fetchNotes = async (attempt = 0) => {
    setLoading(true);
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    try {
      const { data } = await getMeetingNotes(id);
      setMeetingEventData(data);
      updateMeetingNotes(data?.summary);
      setNoteStatus(data?.event?.status);
      if (data?.event?.summary_json) {
        setMeetingSummary(data?.event?.summary_json);
      }

      if (
        ['CREATED', 'IN PROGRESS'].includes(data?.event?.status) &&
        attempt < MAX_ATTEMPTS
      ) {
        setMeetingNotes(data?.summary || {});
        timeoutRef = setTimeout(() => {
          if (attempt < MAX_ATTEMPTS) {
            fetchNotes(attempt + 1);
          }
        }, 2000);
        return;
      } else if (
        !data?.summary?.company &&
        data?.event?.status !== 'COMPLETED'
      ) {
        console.error('Error fetching notes:', data?.event?.message);
        setFailed(true);
        return;
      }
      if (!initialUpdatedAt) {
        setInitialUpdatedAt(data?.event?.updated_at);
      }
      setUpdatedTime(data?.event);
      setLoading(false);
    } catch (error: any) {
      const { statusCode, body } = error.response;
      if (
        statusCode === 403 &&
        modalOpenedRef.current === false &&
        JSON.parse(body as string).error ===
          'Access denied: Subscription has ended.'
      ) {
        modalOpenedRef.current = true;
        setShowEndSubscriptionModal(true);
      }
      timeoutRef = setTimeout(() => {
        if (attempt < 5) {
          fetchNotes(attempt + 1);
        } else {
          console.error('Error fetching notes:', error);
        }
      }, 2000);
      console.error('Error fetching notes:', error);
    }
  };

  const fetchGeneralTabSetting = async () => {
    setLoadingBlocks(true);
    const path = `setting/general/${userDetails?.userInfo?.id}`;
    try {
      const response = await doGet(path);
      if (response) {
        setBlocks(response.data[0].preferences.blocks);
      } else {
        setBlocks(DEFAULT_BLOCKS);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadingBlocks(false);
    }
  };

  const fetchUpdatedNote = async () => {
    setLoading(true);
    try {
      const domains = meetingEventData.summary.enrichedExternalAttendees.map(
        (item: any) => item.attendee.split('@')[1]
      );

      const domain = domains?.[0];
      const payload = {
        start_time: meetingEventData.summary.event.startTime,
        end_time: meetingEventData.summary.event.endTime,
        description: meetingEventData.summary.event.description,
        summary: meetingEventData.summary.event.summary,
        attendees: meetingEventData.summary.event.attendees,
        organizer: meetingEventData.summary.event.organizer,
        meeting_id: meetingEventData.summary.event.meetingId,
        domain,
        meeting_type:
          meetingEventData.summary.enrichedExternalAttendees?.length > 0
            ? 'external'
            : 'internal',
        user_id: meetingEventData.summary.event.userId,
        user_email: meetingEventData.summary.event.userEmail,
        event_summary_id: meetingEventData.event.id,
      };
      const response = await doPut(
        `events/${meetingEventData.event.id}`,
        payload
      );
      if (response.data.summaryId) {
        setNoteStatus('CREATED');
        message.success('Meeting note updated sucessfully');
        setTimeout(() => {
          fetchNotes();
        }, 2000);
      }
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchNotes(1), fetchGeneralTabSetting()]);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const firstVisibleBlock = blocks.find((block) => !block.is_hidden);
    if (firstVisibleBlock) {
      setSelectedSection(firstVisibleBlock.identifier);
    }
  }, [blocks]);

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const handleScroll = debounce(() => {
    const sections = [
      { ref: aboutRef, identifier: ABOUT_KEY },
      { ref: attendeesRef, identifier: ATTENDEES_KEY },
      { ref: caughtUpRef, identifier: CALL_CONTEXT_KEY },
      // Dynamically add custom research sections
      ...blocks
        .filter((block) => block.type === 'custom_research')
        .map((block) => ({
          ref: { current: researchRefs.current[block.identifier] },
          identifier: block.identifier,
        })),
    ].filter((section) => section.ref.current);

    const wrapper = document.querySelector('.main');
    if (wrapper) {
      const scrollTop = wrapper.scrollTop;

      const inViewSections = sections
        .filter((section) => {
          if (section.ref.current) {
            const { offsetTop, clientHeight } = section.ref.current;
            return (
              offsetTop <= scrollTop + 100 &&
              offsetTop + clientHeight > scrollTop
            );
          }
          return false;
        })
        .map((section) => section.identifier);

      // Use the last (bottom-most) in-view section
      const bottomMostSection = inViewSections[inViewSections.length - 1];

      // Update selected section if changed
      if (bottomMostSection && bottomMostSection !== selectedSection) {
        setSelectedSection(bottomMostSection);
      }
    }
  }, 100);

  useEffect(() => {
    const wrapper = document.querySelector('.main');
    if (wrapper) {
      wrapper.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener('scroll', handleScroll);
      }
    };
  }, [blocks]);

  return (
    <div className="meeting_notes_wrapper" ref={chatContainerRef}>
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xxl={4}
          className="menu-section summary-points-menu"
        >
          <div style={screens.lg ? { position: 'fixed' } : {}}>
            <Breadcrumb separator=">" className="mb-20">
              <Breadcrumb.Item>
                <Link to="/" onClick={() => dispatch(setDateChange(false))}>
                  My meetings
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Notes</Breadcrumb.Item>
            </Breadcrumb>
            {screens.lg && (
              <Button
                disabled={
                  ['CREATED', 'IN PROGRESS'].includes(noteStatus) ||
                  meetingEventData?.summary?.event?.userEmail !==
                    userDetails?.userInfo?.email
                }
                onClick={() => {
                  fetchUpdatedNote();
                }}
                className="p-0"
                type="text"
                icon={
                  <SyncOutlined
                    spin={['CREATED', 'IN PROGRESS'].includes(noteStatus)}
                  />
                }
              >
                <Typography.Text type="secondary">
                  {noteStatus === 'COMPLETED' &&
                    `Updated ${timeAgo(updatedTime?.updated_at)} ago`}
                  {noteStatus === 'CREATED' && `Queued`}
                  {noteStatus === 'IN PROGRESS' && `Generating...`}
                  {noteStatus === 'FAILED' && `Failed, try again`}
                </Typography.Text>
              </Button>
            )}

            {screens.lg ? (
              loadingBlocks ? (
                <Skeleton
                  active
                  paragraph={{ rows: 5 }}
                  style={{ marginTop: '15px' }}
                />
              ) : (
                <div className="summary-title">
                  {blocks
                    .filter(
                      ({ identifier }) =>
                        identifier !== 'questions' && identifier !== 'research'
                    )
                    .map((block, index) =>
                      !block.is_hidden ? (
                        <Typography.Text
                          key={index}
                          className={`summary-points ${
                            selectedSection === block.identifier ? 'Active' : ''
                          }`}
                          onClick={() =>
                            handleScrollToSection(block.identifier)
                          }
                        >
                          {block.title}
                          {block.identifier === ABOUT_KEY &&
                          meetingNotes?.company?.profile?.name
                            ? ` - ${meetingNotes?.company?.profile?.name}`
                            : ''}
                        </Typography.Text>
                      ) : null
                    )}
                </div>
              )
            ) : null}
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={18}
          xxl={20}
          className="content-section"
        >
          {!loading && failed && (
            <div>
              <h1>Failed to fetch meeting notes</h1>
              <p>
                There was an error fetching the meeting notes. Please try again
                later.
              </p>
            </div>
          )}

          {!failed && (
            <div>
              <NotesHeader
                summary={meetingSummary?.summary || meetingNotes?.summary}
                startTime={
                  meetingSummary?.start_time || meetingNotes?.startTime
                }
                endTime={meetingSummary?.end_time || meetingNotes?.endTime}
                domain={meetingSummary?.domain || meetingNotes?.event?.domain}
              />

              <Divider style={{ background: '#f5f5f5' }} />

              <div className="note-body-section">
                {(blocks || []).map((block) => {
                  if (block.is_hidden) return null;
                  return (
                    <>
                      {block.identifier === ABOUT_KEY && (
                        <div ref={aboutRef} key="about">
                          <AboutSection
                            loading={loading}
                            title={block?.title}
                            company={meetingNotes?.company}
                            about={
                              meetingNotes?.aiSummary?.generated_schema
                                ?.about || {}
                            }
                            news={
                              meetingNotes?.aiSummary?.generated_schema?.news ||
                              []
                            }
                            crmCompany={
                              meetingNotes?.crmNotes?.companies?.[
                                meetingNotes?.event?.domain
                              ]
                            }
                            crmId={meetingNotes?.crmNotes?.portalId}
                            crmProvider={meetingNotes?.crmNotes?.provider}
                          />
                          <Divider style={{ background: '#FFF' }} />
                        </div>
                      )}

                      {block.identifier === ATTENDEES_KEY && (
                        <div ref={attendeesRef} key="who-is-coming">
                          <AttendeeSection
                            loading={loading}
                            title={block?.title}
                            attendees={meetingNotes?.enrichedExternalAttendees}
                            contacts={meetingNotes?.crmNotes?.contacts || {}}
                            crmId={meetingNotes?.crmNotes?.portalId}
                            crmProvider={meetingNotes?.crmNotes?.provider}
                          />
                          <Divider style={{ background: '#FFF' }} />
                        </div>
                      )}

                      {block.identifier === CALL_CONTEXT_KEY && (
                        <div ref={caughtUpRef} key="get-caught-up">
                          <CatchUp
                            loading={loading}
                            title={block?.title}
                            summary={
                              meetingNotes?.aiSummary?.generated_schema
                                ?.callSetup?.summary || ''
                            }
                            emailNotes={
                              meetingNotes?.enrichedExternalAttendees?.[0]
                                ?.emailSummary || ''
                            }
                            crmNotes={meetingNotes?.crmNotes?.companies}
                            crmProvider={meetingNotes?.crmNotes?.provider || ''}
                            transcriptNotes={
                              meetingNotes?.enrichedExternalAttendees?.[0]
                                ?.transcripts || []
                            }
                          />
                          <Divider style={{ background: '#FFF' }} />
                        </div>
                      )}
                      {block.type === 'custom_research' && (
                        <div
                          key={block.identifier}
                          ref={(el) => {
                            // Ensure the ref is added to the researchRefs
                            if (el) {
                              researchRefs.current[block.identifier] = el;
                            }
                          }}
                        >
                          <Research
                            loading={loading}
                            title={block?.title}
                            researchItems={(
                              meetingNotes?.research || []
                            ).filter(
                              (researchItem) =>
                                researchItem.id === block.identifier
                            )}
                          />
                          <Divider style={{ background: '#f5f5f5' }} />
                        </div>
                      )}
                    </>
                  );
                })}

                {(!blocks || blocks.length === 0) && (
                  <>
                    <div ref={aboutRef}>
                      <AboutSection
                        loading={loading}
                        company={meetingNotes?.company}
                        about={
                          meetingNotes?.aiSummary?.generated_schema?.about || {}
                        }
                        news={
                          meetingNotes?.aiSummary?.generated_schema?.news || []
                        }
                        crmCompany={
                          meetingNotes?.crmNotes?.companies?.[
                            meetingNotes?.event?.domain
                          ]
                        }
                        crmId={meetingNotes?.crmNotes?.portalId}
                        crmProvider={meetingNotes?.crmNotes?.provider}
                      />
                      <Divider style={{ background: '#FFF' }} />
                    </div>

                    <div ref={attendeesRef}>
                      <AttendeeSection
                        loading={loading}
                        attendees={meetingNotes?.enrichedExternalAttendees}
                        contacts={meetingNotes?.crmNotes?.contacts || {}}
                        crmId={meetingNotes?.crmNotes?.portalId}
                        crmProvider={meetingNotes?.crmNotes?.provider}
                      />
                      <Divider style={{ background: '#FFF' }} />
                    </div>

                    <div ref={caughtUpRef}>
                      <CatchUp
                        loading={loading}
                        summary={
                          meetingNotes?.aiSummary?.generated_schema?.callSetup
                            ?.summary || ''
                        }
                        emailNotes={
                          meetingNotes?.enrichedExternalAttendees?.[0]
                            ?.emailSummary || ''
                        }
                        crmNotes={meetingNotes?.crmNotes?.companies}
                        crmProvider={meetingNotes?.crmNotes?.provider || ''}
                        transcriptNotes={
                          meetingNotes?.enrichedExternalAttendees?.[0]
                            ?.transcripts || []
                        }
                      />
                      <Divider style={{ background: '#FFF' }} />
                    </div>
                    {blocks?.map((block) => {
                      if (block.type === 'custom_research') {
                        return (
                          <div
                            key={block.identifier}
                            ref={(el) => {
                              if (el) {
                                researchRefs.current[block.identifier] = el;
                              }
                            }}
                          >
                            <Research
                              loading={loading}
                              title={block?.title}
                              researchItems={(
                                meetingNotes?.research || []
                              ).filter(
                                (researchItem) =>
                                  researchItem.id === block.identifier
                              )}
                            />
                            <Divider style={{ background: '#f5f5f5' }} />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </>
                )}
                <ChatWrapper
                  noteId={id}
                  tools={[
                    meetingNotes?.crmNotes?.provider,
                    'gmail',
                    'gcalendar',
                    'web',
                  ]}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
      <SubscriptionExpiredModal
        setShowEndSubscriptionModal={setShowEndSubscriptionModal}
        showEndSubscriptionModal={showEndSubscriptionModal}
        users={userDetails?.userInfo?.id}
      />
      <ShareNote />
    </div>
  );
};
